import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
<path d="M2476 3974 c-3 -9 -6 -56 -6 -104 0 -82 2 -90 22 -101 18 -10 24 -8
40 11 14 17 18 40 18 97 0 41 -5 83 -10 94 -13 23 -55 25 -64 3z"/>
<path d="M2712 3938 c-16 -21 -72 -159 -72 -179 0 -20 46 -38 60 -23 10 11 60
131 67 162 9 39 -31 68 -55 40z"/>
<path d="M2237 3933 c-15 -14 -5 -55 30 -133 29 -66 40 -80 56 -78 39 6 42 30
12 104 -15 38 -33 79 -41 92 -14 21 -43 29 -57 15z"/>
<path d="M2386 3674 c-38 -8 -96 -30 -129 -47 -99 -53 -231 -196 -211 -228 4
-8 3 -9 -4 -5 -18 11 -43 -50 -57 -137 -17 -109 -5 -197 40 -293 46 -100 204
-338 233 -351 25 -12 27 -7 11 34 -11 31 -104 177 -131 207 -31 35 -89 167
-105 242 -15 68 -15 80 -1 147 36 162 126 281 265 349 62 30 77 33 179 36 81
3 125 -1 167 -13 121 -35 252 -156 299 -275 17 -45 22 -77 22 -170 1 -102 -2
-122 -25 -176 -21 -50 -108 -196 -129 -214 -5 -5 -43 -63 -74 -113 -10 -16
-20 -54 -23 -84 -5 -47 -3 -58 12 -69 34 -25 42 -17 44 48 3 71 19 109 101
223 31 44 71 108 88 142 41 78 76 216 67 263 -3 19 -8 50 -10 67 -3 18 -15 59
-27 90 -88 240 -352 383 -602 327z"/>
<path d="M2381 3454 c-12 -15 -21 -31 -21 -36 0 -5 -16 -6 -35 -2 -53 10 -85
-15 -85 -66 0 -45 26 -70 72 -70 19 0 28 -5 28 -16 0 -14 -8 -16 -42 -11 -41
5 -78 34 -91 69 -3 10 -9 10 -23 2 -28 -17 -40 -59 -27 -95 8 -21 9 -39 1 -60
-23 -65 64 -117 113 -68 18 18 20 29 15 65 -4 34 -2 44 8 44 34 0 36 -89 2
-125 -28 -30 -25 -53 10 -86 22 -21 28 -22 67 -12 23 6 44 9 46 7 3 -3 -3 -33
-12 -68 -22 -84 -22 -85 14 -50 38 35 99 127 99 148 0 9 16 22 35 30 19 8 35
19 35 23 0 19 -31 51 -55 57 -31 8 -33 26 -2 26 30 0 66 -26 77 -56 19 -50 80
-13 80 48 0 33 -31 68 -60 68 -11 0 -20 7 -20 15 0 19 23 19 60 0 34 -18 50
-43 50 -79 0 -22 5 -26 28 -26 31 0 47 14 80 73 26 45 27 66 8 93 -14 19 -15
19 -38 -2 -28 -27 -62 -37 -88 -29 -30 10 -24 25 10 25 35 0 80 38 80 67 0 53
-70 98 -136 89 -26 -3 -46 -1 -50 5 -10 16 -50 28 -73 22 -16 -4 -21 -13 -21
-40 0 -37 -15 -73 -30 -73 -6 0 -7 19 -3 45 7 55 -7 75 -52 75 -24 0 -39 -7
-54 -26z m270 -63 c6 -16 19 -31 30 -34 21 -6 26 -27 7 -27 -27 0 -112 36
-116 49 -3 10 2 12 22 7 22 -5 26 -3 26 14 0 30 20 24 31 -9z m-143 -109 c2
-12 13 -28 24 -36 30 -21 13 -42 -18 -22 -13 9 -34 16 -47 16 -25 0 -52 22
-40 33 3 4 17 4 29 1 20 -5 24 -2 24 16 0 28 24 21 28 -8z m-110 -141 c9 -6
30 -12 45 -16 15 -3 30 -12 34 -21 8 -20 -5 -30 -19 -16 -18 18 -28 15 -28 -8
0 -11 -7 -20 -15 -20 -8 0 -15 9 -15 20 0 11 -11 27 -25 36 -37 24 -17 46 23
25z"/>
<path d="M2253 2563 c-51 -10 -54 -62 -5 -81 53 -21 426 -102 469 -102 13 0
33 10 44 21 l21 21 -23 24 c-18 17 -55 30 -144 49 -66 14 -160 35 -208 45
-120 27 -129 28 -154 23z m480 -112 c20 -20 -4 -28 -52 -17 -54 12 -66 20 -57
34 7 11 95 -3 109 -17z"/>
<path d="M2227 2412 c-41 -45 -4 -69 153 -102 52 -11 147 -31 211 -45 129 -27
172 -24 177 14 4 26 -14 51 -37 51 -14 0 -14 -2 -1 -10 11 -7 12 -12 4 -20
-16 -16 -109 10 -109 30 0 10 9 14 25 13 44 -4 -22 13 -202 52 -194 41 -199
41 -221 17z"/>
<path d="M2688 2333 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2223 2279 c-30 -30 -3 -72 52 -83 36 -7 87 -18 215 -46 136 -31 225
-50 228 -50 2 0 0 5 -4 12 -4 7 -3 8 5 4 6 -4 20 -4 31 -1 27 9 26 47 -2 71
-20 18 -21 18 -15 1 4 -11 5 -22 2 -27 -8 -13 -110 15 -110 31 0 15 0 15 60 4
22 -4 38 -5 35 -2 -5 6 -270 67 -305 71 -12 1 -26 6 -33 10 -24 15 -146 19
-159 5z"/>
<path d="M2314 2124 c2 -16 4 -32 5 -36 0 -4 16 -24 33 -44 34 -39 98 -73 135
-74 47 0 163 70 163 100 0 4 -15 11 -32 15 -18 4 -78 17 -133 30 -55 13 -117
27 -137 30 -36 7 -37 6 -34 -21z m69 -39 c15 -19 41 -42 58 -51 25 -12 28 -18
19 -29 -19 -23 -74 9 -113 65 -39 56 -7 69 36 15z"/>
<path d="M2642 1854 c-37 -26 -23 -74 23 -74 44 0 63 60 26 80 -25 13 -22 13
-49 -6z"/>
<path d="M1740 1665 l0 -175 34 0 34 0 4 90 c3 97 10 110 59 110 41 0 49 -17
49 -112 l0 -88 40 0 40 0 0 90 c0 104 -14 141 -60 165 -35 18 -95 11 -104 -11
-13 -33 -26 -10 -26 46 l0 60 -35 0 -35 0 0 -175z"/>
<path d="M2490 1835 c0 -3 -1 -75 -1 -160 0 -85 1 -162 1 -170 0 -10 11 -15
35 -15 l35 0 0 175 0 175 -35 0 c-19 0 -35 -2 -35 -5z"/>
<path d="M3100 1665 l0 -175 34 0 34 0 4 90 c3 97 10 110 59 110 41 0 49 -17
49 -112 l0 -88 40 0 40 0 0 90 c0 104 -14 141 -60 165 -35 18 -95 11 -104 -11
-13 -33 -26 -10 -26 46 l0 60 -35 0 -35 0 0 -175z"/>
<path d="M1430 1800 c0 -29 2 -30 45 -30 l45 0 0 -140 0 -140 40 0 40 0 0 140
0 140 45 0 c43 0 45 1 45 30 l0 30 -130 0 -130 0 0 -30z"/>
<path d="M3430 1796 c0 -21 -6 -36 -15 -40 -8 -3 -15 -16 -15 -30 0 -13 7 -26
15 -30 11 -4 15 -22 15 -76 0 -39 4 -80 10 -90 10 -20 59 -40 96 -40 21 0 24
5 24 35 0 31 -3 35 -25 35 -17 0 -26 7 -29 23 -4 18 -3 80 3 108 0 4 11 6 24
5 18 -1 23 5 25 31 3 28 0 32 -25 35 -23 2 -29 8 -31 36 -3 29 -6 32 -38 32
-32 0 -34 -2 -34 -34z"/>
<path d="M2135 1753 c-89 -24 -124 -148 -61 -216 28 -30 94 -50 133 -41 33 8
83 51 83 72 0 14 -25 13 -108 -4 -31 -6 -62 7 -62 27 0 4 42 10 93 11 l92 3
-2 30 c-3 42 -20 77 -46 96 -25 18 -91 30 -122 22z m85 -83 c10 -19 8 -20 -45
-20 -30 0 -55 4 -55 8 0 35 83 45 100 12z"/>
<path d="M2630 1625 l0 -135 40 0 41 0 -2 135 -1 135 -39 0 -39 0 0 -135z"/>
<path d="M2830 1747 c-47 -16 -84 -85 -75 -141 15 -97 112 -146 178 -88 16 14
17 12 17 -24 0 -22 -5 -45 -12 -52 -17 -17 -73 -15 -88 3 -18 22 -80 20 -80
-3 0 -57 98 -96 174 -70 72 24 81 47 84 229 l3 159 -40 0 c-35 0 -41 -3 -41
-22 0 -19 -1 -20 -17 -5 -26 23 -63 28 -103 14z m104 -73 c21 -20 20 -73 -1
-96 -34 -38 -103 -11 -103 41 0 60 65 94 104 55z"/>
<path d="M980 1281 c0 -5 -3 -16 -6 -25 -3 -9 -1 -16 5 -16 6 0 11 6 11 14 0
15 29 30 38 21 12 -12 -19 -148 -36 -161 -15 -12 -11 -13 31 -14 26 0 47 5 47
10 0 6 -4 10 -10 10 -13 0 -13 36 1 100 8 39 15 50 30 50 10 0 19 -7 19 -15 0
-8 5 -15 10 -15 6 0 10 11 10 25 0 24 -2 25 -75 25 -41 0 -75 -4 -75 -9z"/>
<path d="M1152 1253 c-1 -21 -6 -62 -12 -92 -12 -66 -13 -61 4 -61 21 0 24 7
36 86 6 38 13 77 16 87 4 13 0 17 -19 17 -22 0 -25 -5 -25 -37z"/>
<path d="M1442 1237 c-7 -115 -7 -130 3 -133 6 -2 17 1 25 6 11 7 12 12 4 20
-9 9 -8 26 2 68 19 81 18 92 -8 92 -21 0 -23 -6 -26 -53z"/>
<path d="M1764 1258 c0 -18 -3 -61 -8 -95 -7 -52 -5 -63 7 -63 16 0 32 53 43
138 6 50 5 52 -18 52 -21 0 -24 -5 -24 -32z"/>
<path d="M2040 1281 c0 -6 4 -12 9 -15 11 -7 -12 -130 -29 -150 -11 -14 -7
-16 34 -16 80 0 136 34 150 92 9 40 -8 74 -45 87 -39 13 -119 15 -119 2z m121
-33 c12 -22 4 -73 -17 -105 -10 -16 -24 -23 -45 -23 -27 0 -30 3 -26 23 3 12
9 45 13 72 4 28 11 53 16 56 16 10 48 -2 59 -23z"/>
<path d="M2953 1276 c3 -7 2 -31 -3 -52 -23 -112 -23 -112 9 -119 46 -10 89
17 97 61 7 41 -11 69 -39 58 -18 -7 -23 -24 -7 -24 11 0 13 -39 4 -64 -3 -9
-15 -16 -26 -16 -18 0 -20 4 -14 33 4 17 9 50 12 72 3 22 7 46 9 53 3 7 -5 12
-21 12 -18 0 -24 -4 -21 -14z"/>
<path d="M1540 1264 c0 -17 22 -14 28 4 2 7 -3 12 -12 12 -9 0 -16 -7 -16 -16z"/>
<path d="M1952 1258 c-7 -7 -12 -18 -12 -25 0 -7 -3 -13 -7 -13 -5 0 -9 -24
-11 -54 -2 -58 13 -76 46 -56 10 5 12 10 5 10 -26 0 -10 90 16 91 10 0 11 2 4
6 -7 2 -13 16 -13 29 0 26 -10 30 -28 12z"/>
<path d="M2424 1262 c-16 -11 -26 -42 -31 -98 -3 -40 -1 -53 11 -57 19 -8 46
1 46 15 0 5 -4 7 -9 3 -16 -10 -21 11 -10 48 6 24 16 37 27 37 16 1 16 1 0 10
-10 6 -14 18 -11 30 5 20 -4 25 -23 12z"/>
<path d="M1220 1218 c-22 -8 -23 -10 -8 -19 13 -7 16 -17 11 -39 -8 -41 1 -60
27 -53 13 4 18 9 12 15 -5 5 -7 29 -5 52 6 49 -1 58 -37 44z"/>
<path d="M1350 1223 c-31 -12 -50 -37 -50 -68 0 -40 20 -58 54 -49 36 9 43 23
10 21 -40 -3 -40 20 0 37 38 16 49 49 20 60 -9 3 -16 6 -17 5 -1 0 -9 -3 -17
-6z m30 -27 c0 -14 -29 -31 -38 -22 -2 3 -2 12 1 21 8 19 37 20 37 1z"/>
<path d="M1627 1208 c-31 -28 -41 -69 -23 -93 11 -15 16 -16 37 -4 20 11 21
14 7 17 -35 7 -14 82 22 82 24 0 24 -1 7 -43 -8 -18 -12 -38 -9 -45 2 -7 1
-24 -2 -37 -5 -21 -13 -25 -41 -25 -38 0 -47 -14 -14 -23 52 -14 91 35 104
129 6 48 5 52 -17 58 -37 9 -45 7 -71 -16z"/>
<path d="M1826 1214 c-20 -20 -20 -25 0 -17 15 5 16 0 10 -40 -7 -46 1 -62 26
-52 17 7 22 106 5 117 -17 11 -24 10 -41 -8z"/>
<path d="M2265 1218 c-30 -17 -44 -40 -45 -68 0 -32 16 -49 41 -43 20 5 27 23
9 23 -11 0 -13 39 -4 64 3 9 15 16 25 16 20 0 24 -20 10 -55 -12 -29 -2 -45
27 -45 19 0 23 3 15 11 -7 7 -8 24 -1 51 5 22 7 42 5 44 -11 11 -65 13 -82 2z"/>
<path d="M2525 1218 c-31 -18 -44 -40 -45 -74 0 -33 18 -48 45 -38 17 7 20 24
5 24 -11 0 -13 39 -4 64 3 9 15 16 26 16 21 0 22 -19 4 -61 -9 -21 19 -50 40
-42 10 3 12 9 6 15 -6 6 -6 25 0 50 5 22 7 42 5 44 -11 11 -65 13 -82 2z"/>
<path d="M2665 1216 c-17 -12 -18 -18 -7 -40 6 -14 16 -26 22 -26 5 0 10 -7
10 -15 0 -17 -21 -20 -37 -4 -8 8 -13 7 -18 -1 -15 -25 39 -39 76 -20 26 15
24 45 -6 64 -30 20 -32 36 -5 36 11 0 20 -4 20 -10 0 -5 5 -10 10 -10 16 0 11
27 -6 34 -24 9 -38 7 -59 -8z"/>
<path d="M2790 1224 c-11 -3 -16 -20 -17 -58 -2 -60 11 -75 44 -51 12 10 14
14 4 15 -11 0 -12 11 -7 50 4 28 3 49 -1 49 -4 -1 -15 -3 -23 -5z"/>
<path d="M2862 1198 c-6 -18 -13 -40 -16 -49 -8 -19 19 -50 37 -43 18 7 20
124 2 124 -8 0 -18 -15 -23 -32z"/>
<path d="M3175 1204 c-47 -51 -22 -114 40 -98 31 8 33 21 3 20 -18 -1 -24 5
-26 29 -1 17 2 37 8 45 8 13 13 13 31 2 18 -12 20 -11 17 5 -5 29 -45 28 -73
-3z"/>
<path d="M3315 1219 c-50 -28 -60 -79 -21 -104 56 -37 134 36 98 92 -11 18
-55 24 -77 12z m45 -38 c0 -42 -10 -61 -31 -61 -14 0 -19 7 -19 29 0 42 10 61
31 61 14 0 19 -7 19 -29z"/>
<path d="M3510 1215 c-10 -12 -10 -15 0 -15 9 0 11 -13 6 -50 -6 -42 -4 -50 9
-50 9 0 17 6 19 13 2 6 8 27 14 45 17 53 -17 94 -48 57z"/>
<path d="M3600 1218 c-19 -7 -21 -11 -11 -18 10 -5 12 -19 8 -42 -7 -46 3 -61
33 -50 15 6 19 11 12 16 -7 4 -9 22 -6 50 7 49 0 58 -36 44z"/>
<path d="M3775 1222 c-17 -2 -20 -11 -21 -62 -1 -45 2 -60 13 -60 7 0 15 6 17
13 2 6 8 27 14 45 13 41 4 68 -23 64z"/>
<path d="M3833 1215 c-12 -10 -14 -14 -4 -15 10 0 12 -10 8 -39 -6 -50 3 -64
33 -53 15 6 19 11 12 16 -7 4 -9 22 -6 50 7 49 -10 65 -43 41z"/>
<path d="M3940 1202 c-49 -50 -32 -120 22 -91 20 11 20 14 6 17 -36 7 -17 82
20 82 11 0 13 -8 8 -32 -3 -18 -7 -40 -7 -48 -2 -57 -10 -70 -45 -70 -38 0
-46 -15 -13 -23 28 -8 64 10 77 37 12 28 35 136 30 142 -3 2 -19 7 -36 10 -26
5 -36 1 -62 -24z"/>
<path d="M1523 1198 c1 -13 -2 -34 -5 -48 -9 -34 3 -51 30 -43 17 4 20 8 11
19 -6 7 -8 22 -5 34 3 11 9 29 12 40 4 16 1 20 -19 20 -19 0 -25 -5 -24 -22z"/>
<path d="M3434 1160 c-1 -74 17 -80 39 -14 18 54 13 74 -17 74 -19 0 -21 -6
-22 -60z"/>
<path d="M3084 1135 c-3 -8 -3 -19 0 -24 9 -13 36 -2 36 14 0 19 -30 27 -36
10z"/>
<path d="M3670 1127 c0 -19 10 -27 26 -20 21 8 17 33 -6 33 -11 0 -20 -6 -20
-13z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
